import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {BehaviorSubject, Observable} from "rxjs";
import { TechnicianDecodedToken } from "./technician.model";
import { KeycloakService } from "keycloak-angular";
import { decodeToken } from "../../shared/utils/utils";
import {Company, LocationList, TechOverviewResponse} from "@apruv-client/services";

@Injectable({ providedIn: 'root' })
export class TechnicianLayoutService {
    companySelected$: Observable<Company | undefined>;
    locationSelected$: Observable<LocationList | undefined>;

    private locationId?: number;
    private companyId = 1
    private isTipEnable: boolean = false;
    private isLeaderboardEnabled = false;
    private isServiceEnabled = false;
    private companyIdSubject = new BehaviorSubject<number>(0);
    private locationIdSubject = new BehaviorSubject<number | undefined>(undefined);
    private companySelectedSubject = new BehaviorSubject<Company | undefined>(undefined);
    private locationSelectedSubject = new BehaviorSubject<LocationList | undefined>(undefined);

    private profileDetailsSubject = new BehaviorSubject<TechnicianDecodedToken | undefined>(undefined);
    profileDetails$ = this.profileDetailsSubject.asObservable();

    private techOverviewSubject = new BehaviorSubject<TechOverviewResponse | undefined>(undefined);
    techOverview$ = this.techOverviewSubject.asObservable();

    constructor(private http: HttpClient, private keycloak: KeycloakService) {
      this.companySelected$ = this.companySelectedSubject.asObservable();
      this.locationSelected$ = this.locationSelectedSubject.asObservable();
    }

    setCompanyId(item: number | undefined) {
      this.companyId = item || 1;
      sessionStorage.setItem('companyId', String(this.companyId));
      this.companyIdSubject.next(this.companyId);
    }

    setLocationId(item: number | undefined) {
      this.locationId = item;
      sessionStorage.setItem('locationId', String(this.locationId));
      this.locationIdSubject.next(this.locationId);
    }

    get technicianProfile(): TechnicianDecodedToken | undefined {
        return this.profileDetailsSubject.value;
    }

    set technicianProfile(company: TechnicianDecodedToken | undefined) {
        this.profileDetailsSubject.next(company);
    }

    get isTip(): boolean | undefined {
      return this.isTipEnable;
    }

    set isTip(tip: boolean) {
        this.isTipEnable = tip;
    }

    get showService(): boolean | undefined {
      return this.isServiceEnabled;
    }

    set showService(tip: boolean) {
        this.isServiceEnabled = tip;
    }

    get showLeaderboard(): boolean | undefined {
      return this.isLeaderboardEnabled;
    }

    set showLeaderboard(tip: boolean) {
        this.isLeaderboardEnabled = tip;
    }

    get techOverview(): TechOverviewResponse | undefined {
        return this.techOverviewSubject.value;
    }

    set techOverview(overview: TechOverviewResponse | undefined) {
        this.techOverviewSubject.next(overview);
    }

    async updateUserDetails() {
        const token = await this.getToken();
        if (!token) return;

        const decodedToken: any = decodeToken(token);
        this.technicianProfile = decodedToken;
    }

    private async getToken() {
        if (await this.keycloak.isLoggedIn()) {
          const token = await this.keycloak.getToken();
          return token;
        } else {
          console.error("User is not logged in.");
          return null;
        }
    }

    setCookie(name: string, value: string, days: number): void {
      const expires = new Date();
      expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000);
      document.cookie = `${name}=${value};expires=${expires.toUTCString()};path=/`;
    }

    getCookie(name: string): string | null {
      const cookies = document.cookie.split('; ');
      for (const cookie of cookies) {
        const [key, value] = cookie.split('=');
        if (key === name) {
          return value;
        }
      }
      return null;
    }

    deleteCookie(name: string): void {
      document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/`;
    }
}
